import axios, { Method } from "axios";
import { message } from "antd";

const statusCodesMessages: { [key: string]: string[] } = {
	201: ["success", "Operation successful"],
	200: ["success", "Operation successful"],
	400: ["error", "Bad request"],
	403: ["warning", "Forbidden Operation"],
	500: ["error", "Error"],
};

const isInterestingRoute = (url: string, method: string) => {
	if (method === "GET") return false;
	if (url.indexOf("retrieve") !== -1) return false;
	if (url.indexOf("get") !== -1) return false;
	if (url.indexOf("refresh") !== -1) return false;
	if (url.indexOf("query") !== -1) return false;
	if (url.indexOf("exist") !== -1) return false;
	if (url.indexOf("annotation-item/create") !== -1) return false;
	if (url.indexOf("auth/refresh") !== -1) return false;
	if (url.indexOf("login") !== -1) return false;
	if (url.indexOf("generate-synonyms") !== -1) return false;

	return true;
};

export class RequestService {
	/**
	 * Perform request to backend globally
	 * @param url url specification (without base url)
	 * @param method post, get, put ...
	 * @param body data to fetch
	 * @returns response
	 */
	public request = async (
		url: string | undefined,
		method: Method | undefined,
		body: any,
		idToken: string | null = null
	) => {
		let response, error;
		try {
			let token = idToken
				? idToken
				: localStorage.getItem("LettriaIdToken");
			response = await axios({
				url: url,
				method: method,
				data: body,
				headers: {
					Authorization: token
						? ("LettriaIdToken " + token).toString()
						: "Empty",
				},
			});
		} catch (e: any) {
			console.error(e);
			response = e?.response;
			error = e;
		}

		// let isInteresting = isInterestingRoute(url!, method as string);
		// if (
		// 	response &&
		// 	response.status in statusCodesMessages &&
		// 	isInteresting
		// ) {
		// 	let statusCodeData = statusCodesMessages[response.status];
		// 	if (
		// 		statusCodeData[0] === "success" ||
		// 		statusCodeData[0] === "warning" ||
		// 		statusCodeData[0] === "error"
		// 	)
		// 		message[statusCodeData[0]]({
		// 			maxCount: 1,
		// 			content: statusCodeData[1],
		// 			className: "request-response-message",
		// 			// duration: 1000000,
		// 		});
		// } else if (response && isInteresting) {
		// 	message.info({
		// 		content: `${url} : ${response?.status}`,
		// 		className: "request-response-message",
		// 	});
		// }

		if (error) throw error;
		return response?.data;
	};
}
