import { RetrieveOverviewStore } from "./retrieve-overview/store/retrieve-overview.store.service";
import { RetrieveStaffDataApi } from "./retrieve-staff-data/api/retrieve-staffdata.api.service";
import { RetrieveStaffDataStore } from "./retrieve-staff-data/store/retrieve-staffdata.store.service";
import { RetrieveSubscriptionStore } from "./retrieve-subscription/store/retrieve-subscription.store.service";

export class RetrieveDataService {
	private retrieveSubscriptionStore: RetrieveSubscriptionStore =
		new RetrieveSubscriptionStore();

	private retrieveStaffDataStore: RetrieveStaffDataStore =
		new RetrieveStaffDataStore();

	private retrieveOverviewStore: RetrieveOverviewStore =
		new RetrieveOverviewStore();

	public retrieveOverviewAll = async () => {
		await this.retrieveSubscriptionStore.retrieveSubscriptions();
		let service: RetrieveStaffDataApi = new RetrieveStaffDataApi();
		let _result: any = await service.retrieveStaffDataApi();
		await this.retrieveOverviewDictionnaries(_result);
		// await this.retrieveOverviewUserData(_result);
		await this.retrieveOverviewAnnotationGrps(_result);
		await this.retrieveOverviewCampaign(_result);
		await this.retrieveOverviewActive(_result);
		await this.retrieveOverviewDataSets(_result);
		await this.retrieveOverviewGroups(_result);
		return true;
	};

	public getEvolution = async (
		projectIds: string[],
		evolutionFilter: string,
		startDate: Date,
		endDate: Date,
		mergeProjects: boolean = false
	) => {
		// console.log("getEvolution", startDate, endDate);
		return await this.retrieveOverviewStore.getEvolution(
			projectIds,
			evolutionFilter,
			startDate,
			endDate,
			mergeProjects
		);
	};

	public retrieveStaffData = async () => {
		await this.retrieveStaffDataStore.retrieveStaffData();
	};

	public retrieveOverviewCampaign = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewCampaign(_res);
	};

	public retrieveOverviewAnnotationGrps = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewAnnotationGrps(_res);
	};

	public retrieveOverviewDictionnaries = async (_res: any) =>
		await this.retrieveOverviewStore.retrieveOverviewDictionnaries(_res);

	public retrieveOverviewGroups = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewGroups(_res);
	};

	public retrieveOverviewActive = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewActive(_res);
	};

	public retrieveOverviewDataSets = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewDataSets(_res);
	};
	public retrieveOverviewUserData = async (_res: any) => {
		await this.retrieveOverviewStore.retrieveOverviewUserData(_res);
	};

	public addSubscription = async (body: any) => {
		this.retrieveStaffDataStore.addUserSubsData(body);
	};

	public deleteSubscription = async (body: any) => {
		this.retrieveStaffDataStore.deleteUserSubsData(body);
	};

	public modifySubscription = async (id: any, body: any) => {
		this.retrieveStaffDataStore.modifyUserSubsData(id, body);
	};

	public retrieveSubscriptions = async () =>
		this.retrieveSubscriptionStore.retrieveSubscriptions();
}
