import { Layout, Space, Select } from "antd";
import ViewHeader from "common/components/view-header/view-header";
import { Analytics } from "modules/types/overview";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { RootState } from "store/store";
import {
	addDays,
	format,
	formatDistanceToNow,
	formatDistance,
	parse,
} from "date-fns";
import Chart from "./components/chart.component";
import Evolution from "common/components/evolution/evolution.component";

const SettingsViewUserCharts = (props: SettingsViewListUsersProps) => {
	const { userId } = useParams();
	const userData = props.staffDataUsers[userId as string];
	const { Option } = Select;
	const [selectedProject, setSelectedProject] = useState<Analytics>();
	const [projectList, setListProject] = useState<Analytics[]>([]);
	const [projectsAreFetched, setProjectsAreFetched] =
		useState<boolean>(false);

	const retrieveDataService: RetrieveDataService = new RetrieveDataService();
	var userProjects: any = props.user_projects[userId!];

	useEffect(() => {
		if (!userProjects) {
			(async () => {
				try {
					if (userId) {
						let _result: { [key: string]: any } = {};
						_result[userId] = userData;
						await retrieveDataService.retrieveOverviewUserData(
							{ users: _result }
							// userId
						);
					}
				} catch (error) {
					console.error(error);
				}
			})();
		}
	});

	useEffect(() => {
		var list: any[] = [];
		try {
			Object.values(userProjects).map((project) => {
				list.push(project);
			});
			//console.log("list", list);
			list.sort(compareProject);
			setListProject(list);
			setSelectedProject(list[0]);
			setProjectsAreFetched(true);
			let _projects: string[] = [];
			Object.keys(userProjects).map((projectIds) => {
				_projects.push(projectIds);
			});
			(async () => {
				// console.log("projects", _projects);
				// await retrieveDataService.getEvolution(
				// 	_projects,
				// 	"dayOfYear",
				// 	parse("2021-01-01", "yyyy-MM-dd", new Date()),
				// 	parse("2022-12-31", "yyyy-MM-dd", new Date())
				// );
			})();
		} catch (error) {
			console.log(error);
		}
	}, [userProjects]);

	const handleChange = (value: string) => {
		let _projects: any = props.user_projects[userId!];
		setSelectedProject(_projects[value]);
	};
	function compareProject(a: any, b: any) {
		try {
			if (
				Object.keys(a.analyticId.data).length <
				Object.keys(b.analyticId.data).length
			) {
				return 1;
			} else if (
				Object.keys(a.analyticId.data).length >
				Object.keys(b.analyticId.data).length
			) {
				return -1;
			}
		} catch {
			//console.log("a/b", a, b);
		}
		return 0;
	}
	if (userData == null) {
		return <></>;
	} else {
		return (
			<>
				<Layout>
					<ViewHeader
						title={
							userData.profile.firstName +
							" " +
							userData.profile.lastName
						}
						subtitle={userData.email}
						extra={
							<>
								{/* <Select
									key={selectedProject?._id}
									defaultValue={
										selectedProject?._id
											? selectedProject?._id
											: "Select a project"
									}
									style={{ width: 240 }}
									onChange={handleChange}>
									{projectList.map((project) => {
										return (
											<Option value={project._id}>
												{project.name}
											</Option>
										);
									})}
								</Select> */}
							</>
						}
					/>
					<div style={{ padding: "32px" }}>
						<Space
							direction="vertical"
							size="middle"
							style={{ display: "flex" }}>
							{/*selectedProject ? "data" : "No data"*/}
							{/* <Chart
								user_props={props.user_projects[userId!]}
								proj={selectedProject}
								type={"calls_day_graph"}
								key={"calls_day_graph"}
							/>
							<Chart
								user_props={props.user_projects[userId!]}
								proj={selectedProject}
								type={"character_day_graph"}
								key={"character_day_graph"}
							/>
							<Chart
								user_props={props.user_projects[userId!]}
								proj={selectedProject}
								type={"calls_speed_day_graph"}
							/> */}
							<Evolution />
						</Space>
					</div>
				</Layout>
			</>
		);
	}
};

const mapState = (state: RootState) => {
	return {
		staffDataUsers: state.admin.staffData.users,
		overview: state.overview.overall_data,
		user_projects: state.overview.user_projects_map,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewListUsersProps = PropsFromRedux;

export default connector(SettingsViewUserCharts);
