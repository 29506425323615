import { Route, Routes, Navigate } from "react-router-dom";
import SettingsView from "./settings-view.component";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import SettingsViewListusersComponent from "./components/settings-view-list-users/settings-view-list-users.component";
import SettingsViewListDemo from "./components/settings-view-list-demos/settings-view-list-demos.component";
import SettingsOverview from "./components/settings-overview/settings-overview.component";
import SettingsViewUserRoutes from "./components/settings-view-user/setting-view-user.routes";

const SettingsViewRoutes = (props: SettingsViewRoutesProps) => {
	return (
		<Routes>
			<Route element={<SettingsView />}>
				<Route
					path="list-user"
					element={<SettingsViewListusersComponent />}
				/>
				<Route
					path="view-user/:userId/*"
					element={<SettingsViewUserRoutes />}
				/>

				<Route path="demo" element={<SettingsViewListDemo />} />

				<Route path="overview" element={<SettingsOverview />} />

				<Route path="*" element={<Navigate replace to="list-user" />} />
			</Route>
		</Routes>
	);
};

const mapState = (state: RootState) => {
	return {
		// settingsMap: state.settings.map,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewRoutesProps = PropsFromRedux;

export default connector(SettingsViewRoutes);
