import { Link } from "react-router-dom";
import { format } from "date-fns";
import { StaffUser } from "modules/types/admin";

export class UserListService {
	public columns: any = [];

	constructor() {
		this.columns = [
			{
				title: "email",
				dataIndex: "email",
				key: "email",
				render: (value: any, record: StaffUser) => {
					return (
						<Link to={`/admin/settings/view-user/${record?._id}`}>
							<strong>{value}</strong>
						</Link>
					);
				},
			},
			{
				title: "lastConnection",
				dataIndex: "lastConnection",
				render: (date: string) => {
					if (date && Date.parse(date)) {
						return <>{format(Date.parse(date), "yyyy-MM-dd")}</>;
					}
					return "-";
				},
				sorter: (a: StaffUser, b: StaffUser) => {
					if (!a.lastConnection) return -1;
					if (!b.lastConnection) return 1;
					return (
						new Date(a.lastConnection!).getTime() -
						new Date(b.lastConnection!).getTime()
					);
				},
			},
			{
				title: "createdAt",
				dataIndex: "createdAt",
				render: (date: string) => {
					if (date && Date.parse(date)) {
						return <>{format(Date.parse(date), "yyyy-MM-dd")}</>;
					}
					return "-";
				},
				sorter: (a: StaffUser, b: StaffUser) => {
					if (!a.lastConnection) return -1;
					if (!b.lastConnection) return 1;
					return (
						new Date(a.lastConnection!).getTime() -
						new Date(b.lastConnection!).getTime()
					);
				},
			},
			{
				title: "numTokens",
				dataIndex: "numTokens",
			},
		];
	}

	public getColumns = () => {
		return this.columns;
	};
}
