/* eslint-disable jsx-a11y/alt-text */
import { Row, Space } from "antd";
import { AuthStatus } from "./components/user/user-auth-status/user-auth-status.component";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import "./styles/header.style.less";

const HeaderComponent = (props: HeaderComponentProps) => {
	return (
		<header
			id="app-header"
			style={{
				height: "47px",
				background: "#0b2540",
				position: "fixed",
				width: "100%",
				zIndex: "999",
				display: "block",
				verticalAlign: "middle",
				alignItems: "center",
				padding: "0 50px",
				paddingTop: "8px",
				paddingLeft: "250px",
			}}>
			<Space style={{ marginLeft: "-200px" }}>
				<Link
					to={`/`}
					style={{
						marginTop: "12px",
					}}>
					<img
						alt=""
						src="/images/logo.svg"
						style={{
							opacity: 0.9,
							paddingLeft: "-100px",
						}}
					/>
				</Link>

				<Row style={{ marginTop: "4px" }}>
					<Link
						to={`/admin/settings/list-user`}
						style={{
							color: "white",
							marginLeft: "25px",
							marginRight: "25px",
						}}>
						List Users
					</Link>
					<Link
						to={`/admin/settings/overview`}
						style={{
							color: "white",
							marginRight: "25px",
						}}>
						Overview
					</Link>
					<Link
						to={`/admin/settings/demo`}
						style={{
							color: "white",
							//margin: "25px",
						}}>
						Demo
					</Link>
				</Row>
			</Space>
			<Space
				size="middle"
				style={{ float: "right", paddingRight: "0px" }}>
				<AuthStatus />
			</Space>
		</header>
	);
};

const mapState = (state: RootState) => {
	return {
		workspace: state.workspace,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type HeaderComponentProps = PropsFromRedux;

export default connector(HeaderComponent);
