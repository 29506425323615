import { Group } from "modules/types/group";
import { Project } from "modules/types/project";
import { UserData, UserItems } from "modules/types/user";

/**
 * Format Object with **knowledgeIds** attribute to **ontologyIds** attribute
 */
export const formatKnowledgeBeforeRedux = (
	value: UserData | Group[] | Project[]
) => {
	if (Array.isArray(value) && value.length) {
		if ("subscriptionId" in value[0]) {
			// Condition if value = Project
			let projects = value as Project[];
			projects.forEach((project: Project) => {
				if (project?.knowledgeIds) {
					project.ontologyIds = project?.knowledgeIds as string[];
					delete project["knowledgeIds"];
				}
			});
			return projects as Project[];
		} else {
			// Condition if value = Group
			let groups = value as Group[];
			groups.forEach((group: Group) => {
				if (group?.knowledgeIds) {
					group.ontologyIds = group?.knowledgeIds as string[];
					delete group["knowledgeIds"];
				}
			});
			return groups as Group[];
		}
	} else if (typeof value === "object") {
		// Condition if value = Userdata
		let userData = value as UserData;
		if (userData?.knowledgeIds) {
			userData.ontologyIds = userData?.knowledgeIds as UserItems[];
			delete userData["knowledgeIds"];
		}
		return userData as UserData;
	}
};
