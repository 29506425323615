import { AnyAction } from "@reduxjs/toolkit";
import { store } from "../store";
import { UserData } from "modules/types/user";

export const setUser = (user: UserData): AnyAction => {
	return store.dispatch({
		type: "SET_USER",
		payload: user,
	});
};

export const deleteUser = (user: UserData): AnyAction => {
	return store.dispatch({
		type: "DELETE_USER",
		payload: user,
	});
};

export const setLoggedIn = (loggedIn: boolean): AnyAction => {
	return store.dispatch({
		type: "SET_LOGGED_IN",
		payload: loggedIn,
	});
};

export const addRessourceToUser = (
	userKey: string,
	ressource: any
): AnyAction => {
	return store.dispatch({
		type: "ADD_RESSOURCE_TO_USER",
		payload: {
			userKey,
			ressource,
		},
	});
};

export const deleteRessourceFromUser = (
	userKey: string,
	ressourceId: string
): AnyAction => {
	return store.dispatch({
		type: "DELETE_RESSOURCE_TO_USER",
		payload: {
			userKey,
			ressourceId,
		},
	});
};
