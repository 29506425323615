import { Plans } from "./models/subscriptions-references";

export const plans: Plans = {
	free: {
		title: "Free plan",
		label: "Free plan (0.00€/month)",
		value: 3 * 1000,
	},
	pro_1: {
		title: "NLP Basic",
		label: "NLP Basic",
		value: 20 * 1000,
	},
	pro_2: {
		title: "NLP Pro",
		label: "NLP Pro",
		value: 250 * 1000,
	},
	pro_3: {
		title: "NLP Entreprise",
		label: "NLP Entreprise",
		value: 500 * 1000,
	},
	bro: {
		title: "Partenaire Lettria",
		label: "Partenaire Lettria",
		value: 999999000 * 1000,
		description: "Abonnement fourni à nos parternaires",
		style: {
			color: "#385fbd",
			background: "white",
		},
	},
	microsoft_rgpd_1: {
		title: "GDPR Compliance",
		label: "GDPR Compliance",
		value: 30 * 1000,
	},
	microsoft_rgpd_2: {
		title: "GDPR Compliance Pro",
		label: "GDPR Compliance Pro",
		value: 300 * 1000,
	},
	microsoft_rgpd_3: {
		title: "GDPR Compliance Entreprise",
		label: "GDPR Compliance Entreprise",
		value: 600 * 1000,
	},
};
