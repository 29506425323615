import { Select, Input, Button } from "antd";
import {
	getPlans,
	getTitle,
} from "services/subscriptions-references/subscriptions-references.service";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { Component } from "react";
import { format } from "date-fns";
const { Option } = Select;

export class UserInfosService extends Component {
	public columns: any = [];
	public retrieveDataService: RetrieveDataService = new RetrieveDataService();

	public handleUpdate = (id: any, value: string, record: any) => {
		this.retrieveDataService.modifySubscription(id, {
			type: value,
			subscriptionId: record._id,
		});
	};
	public deleteSub = (subId: any, userId: any) => {
		this.retrieveDataService.deleteSubscription({
			userId: userId,
			request: subId,
		});
	};

	constructor(props: any) {
		super(props);
		//console.log(this.props);
		this.columns = [
			{
				title: "Added",
				dataIndex: "dday",
				key: "dday",
				render: (date: string) => (
					<>{format(Date.parse(date), "yyyy-MM-dd")}</>
				),
			},
			{
				title: "Updated at",
				dataIndex: "updatedAt",
				key: "updatedAt",
				render: (date: string) => (
					<>{format(Date.parse(date), "yyyy-MM-dd")}</>
				),
			},
			{
				title: "Type",
				dataIndex: "type",
				key: "type",
				render: (text: any, record: any) => {
					return (
						<>
							<Select
								value={text}
								style={{ width: 120 }}
								onChange={(value) =>
									this.handleUpdate(this.props, value, record)
								}>
								{getPlans().map((element: any) => {
									return (
										<Option key={element} value={element}>
											{getTitle(element)}
										</Option>
									);
								})}
							</Select>
						</>
					);
				},
			},
			{
				title: "Crédit",
				dataIndex: "credit",
				key: "credit",
				render: (text: any) => <Input disabled={true} value={text} />,
			},
			{
				title: "Delete",
				dataIndex: "_id",
				key: "_id",
				render: (key: any) => (
					<Button
						onClick={() => this.deleteSub(key, this.props)}
						type={"primary"}
						danger>
						Delete
					</Button>
				),
			},
		];
	}
}
