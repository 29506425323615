import { RequestService } from "services/request.service";
import { AdminReducer } from "modules/types/admin";
import { Subscription } from "modules/types/subscription";

const requestService: RequestService = new RequestService();

export class RetrieveStaffDataApi extends RequestService {
	public retrieveStaffDataApi = async (): Promise<AdminReducer> => {
		const result: AdminReducer = await requestService.request(
			"auth/retrieve-staff-data",
			"GET",
			{}
		);
		return result;
	};
	public addSubscriptionApi = async (
		body: any
	): Promise<{ subs: Subscription[] }> => {
		const result: { subs: Subscription[] } = await requestService.request(
			"auth/add-subscription",
			"POST",
			body
		);
		return result;
	};
	public deleteSubscriptionApi = async (
		body: any
	): Promise<{ subs: Subscription[] }> => {
		const result = await requestService.request(
			"auth/delete-subscription",
			"DELETE",
			{
				subscriptionId: body,
			}
		);
		return result;
	};

	public modifySubscriptionApi = async (
		body: any
	): Promise<{ subs: Subscription[] }> => {
		const result = await requestService.request(
			"auth/modify-subscription",
			"PUT",
			body
		);
		return result;
	};
}
