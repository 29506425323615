import { AdminReducer } from "modules/types/admin";
import { setStaffData } from "store/admin/admin-action";
import { setWorkspace } from "store/workspace/workspace-actions";

export class AdminStore {
	public setWorkspaceStore = (workspace: string) => {
		setWorkspace(workspace);
	};
	public setStaffStore = (staff: AdminReducer) => {
		setStaffData(staff);
	};
}
