import { RequestService } from "services/request.service";
import { plans } from "./subscriptions-plans.data";

export const getLabel = (plan: string) => {
	if (plan && plan in plans && "label" in plans[plan])
		return plans[plan].label;
	return plan;
};

export const getDescription = (plan: string) => {
	if (plan && plan in plans && "description" in plans[plan])
		return plans[plan].description;
	return plan;
};

export const getStyle = (plan: string) => {
	if (plan && plan in plans && "style" in plans[plan])
		return plans[plan].style;
	return {};
};

export const getTitle = (plan: string) => {
	if (plan && plan in plans && "title" in plans[plan])
		return plans[plan].title;
	return plan;
};

export const getValue = (plan: string) => {
	if (plan && plan in plans && "value" in plans[plan])
		return plans[plan].value;
	return 0;
};

export const getPlans = () => {
	return Object.keys(plans);
};

export const deleteSub = async (id: any) => {
	const requestService: RequestService = new RequestService();

	const result = await requestService.request(
		"auth/delete-subscription",
		"DELETE",
		{
			subscriptionId: id,
		}
	);
};

export default plans;
