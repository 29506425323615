import { AnyAction } from "@reduxjs/toolkit";
import * as Type from "../../modules/types/admin";
import {
	deleteSub,
	modifySub,
	setStaffData,
	updateUserSubs,
} from "./admin-methods";
import { StaffDataActionType } from "../../modules/types/admin/store";

const staff: Type.AdminReducer = {
	staffData: {
		users: {},
		demo: {},
	},
};

export const AdminReducer = (
	state = staff,
	action: AnyAction
): Type.AdminReducer => {
	switch (action.type) {
		case "SET_STAFF_DATA": {
			return setStaffData(state, action as StaffDataActionType);
		}
		case "UPDATE_USER_SUBS": {
			return updateUserSubs(state, action as any);
		}
		case "DELETE_A_SUB": {
			return deleteSub(state, action as any);
		}
		case "MODIFY_A_SUB": {
			return modifySub(state, action as any);
		}
		default: {
			break;
		}
	}
	return state;
};
