import {
	Button,
	Card,
	DatePicker,
	Divider,
	Radio,
	Select,
	Space,
	Spin,
	Typography,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useState, useEffect } from "react";
import { Column, DualAxes, Line } from "@ant-design/charts";
import moment from "moment";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { parse, subMonths } from "date-fns";
import { useParams } from "react-router";
import { FullscreenOutlined } from "@ant-design/icons";

type Config = {
	data: any[];
	height: number;
	xField: string;
	yField: string;
	seriesField: string | undefined;
	geometryOptions?: [
		{
			geometry: string;
		}
	];
};

type Request = {
	project: any;
	requests: any[];
};

const evolutionFilterOptions = [
	{
		value: "week",
		label: "Week",
	},
	{
		value: "month",
		label: "Month",
	},
	{
		value: "dayOfYear",
		label: "Day",
	},
];

type graphSubjectType = "totalCredits" | "totalChars" | "totalRequests";
const graphSubjects: graphSubjectType[] = [
	"totalCredits",
	"totalChars",
	"totalRequests",
];

type ProjectsRequestsOwnProps = {
	projectIds: string[];
};

const ProjectsRequests = (props: ProjectsRequestsProps) => {
	// const dashboardService: AnnotationCampaignViewDashboardService =
	// 	new AnnotationCampaignViewDashboardService();
	const currentTime = new Date();

	const [doMerge, setDoMerge] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const [graphSubject, setGraphSubject] =
		useState<graphSubjectType>("totalCredits");
	const [config, setConfig] = useState<Config>({
		data: [],
		xField: "createdAt",
		yField: graphSubject,
		seriesField: "name",
		height: 450,
	});
	const [isProjectsRequestsData, setIsProjectsRequestsData] = useState(false);
	const [evolutionFilter, setProjectsRequestsFilter] = useState("dayOfYear");
	const [startDate, setStartDate] = useState(subMonths(currentTime, 3));
	const [endDate, setEndDate] = useState(currentTime);
	const [requestsData, setRequestsData] = useState<any>({});
	const [totalCharacters, setTotalCharacters] = useState(0);
	const [totalCredits, setTotalCredits] = useState(0);
	const [totalRequests, setTotalRequests] = useState(0);

	const { userId } = useParams();
	const retrieveDataService: RetrieveDataService = new RetrieveDataService();
	// const { projectRequestMap }: any = props;
	// const userData = props.staffDataUsers[userId as string];
	var userProjects: any = props.user_projects[userId!];

	useEffect(() => {
		let _config = { ...config };
		_config["yField"] = graphSubject;
		setConfig(_config);
	}, [graphSubject]);

	const getRequestsData = async () => {
		let _data = await retrieveDataService.getEvolution(
			props.projectIds,
			evolutionFilter,
			startDate,
			endDate,
			doMerge
		);
		setRequestsData(_data);
	};

	useEffect(() => {
		// var list: any[] = [];
		try {
			getRequestsData();
			// Object.values(userProjects).forEach((project) => {
			// 	list.push(project);
			// });
			// let _projects: string[] = [];
			// Object.keys(userProjects).forEach((projectIds) => {
			// 	_projects.push(projectIds);
			// });
			// (async () => {
			// 	// console.log("projects", _projects);
			// 	await retrieveDataService
			// 		.getEvolution(
			// 			props.projectIds,
			// 			evolutionFilter,
			// 			startDate,
			// 			endDate
			// 		)
			// 		.then(() => setView(!view));
			// })();
		} catch (error) {
			console.log(error);
		}
	}, [props.projectIds, evolutionFilter, startDate, endDate, doMerge]);

	useEffect(() => {
		let _config = { ...config };
		let data: any[] = [];
		// console.log({ requestsData });
		let _totalChars = 0,
			_totalCredits = 0,
			_totalRequests = 0;

		if (doMerge && "all" in requestsData) {
			let projectRequests = requestsData["all"] as Request;
			projectRequests.requests.forEach((request: any) => {
				_totalChars += request.totalChars;
				_totalCredits += request.totalCredits;
				_totalRequests += request.totalRequests;

				data.push({
					...request,
					name: "all",
				});
			});
		} else {
			for (let projectId of props.projectIds) {
				if (projectId in requestsData) {
					let projectRequests = requestsData[projectId] as Request;
					if (!projectRequests?.project?.name)
						console.log("no name", projectRequests);
					projectRequests.requests.forEach((request: any) => {
						_totalChars += request.totalChars;
						_totalCredits += request.totalCredits;
						_totalRequests += request.totalRequests;
						data.push({
							...request,
							name: projectRequests?.project?.name,
						});
					});
				}
			}
		}

		data = data.sort((a, b) => {
			return (
				new Date(a.createdAt).getTime() -
				new Date(b.createdAt).getTime()
			);
		});
		// Object.values(projectRequestMap).forEach((value) => {
		// 	let projectRequests = value as Request;
		// 	projectRequests.requests.forEach((request: any) => {
		// 		data.push({
		// 			...request,
		// 			name: projectRequests.project.name,
		// 		});
		// 	});
		// });

		_config.data = data;
		setConfig(_config);
		setIsProjectsRequestsData(true);
		setTotalCharacters(_totalChars);
		setTotalCredits(_totalCredits);
		setTotalRequests(_totalRequests);
	}, [requestsData]);

	const handleStartDateChange = (v: any) => {
		setStartDate(v);
	};

	const handleEndDateChange = (v: any) => {
		setEndDate(v);
	};

	const handleGraphSubjectChange = (e: any) => {
		setGraphSubject(e.target.value);
	};

	if (!isProjectsRequestsData) return <Spin />;
	return (
		<>
			<Card
				size="small"
				style={
					fullScreen
						? {
								width: "100%",
								height: "100%",
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 1000,
								background: "white",
								padding: "1rem",
						  }
						: {}
				}
				title={<Title level={4}>ProjectsRequests</Title>}
				extra={
					<Space>
						<Radio.Group
							value={graphSubject}
							buttonStyle="solid"
							onChange={handleGraphSubjectChange}>
							<Radio.Button value="totalCredits">
								Credits
							</Radio.Button>
							<Radio.Button value="totalChars">
								Characters
							</Radio.Button>
							<Radio.Button value="totalRequests">
								Requests
							</Radio.Button>
						</Radio.Group>
						<Select
							size="small"
							value={evolutionFilter}
							onChange={(value) => {
								setProjectsRequestsFilter(value);
							}}
							bordered={false}
							style={{ width: 85 }}>
							{evolutionFilterOptions.map((option: any) => (
								<Select.Option value={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select>
						<DatePicker
							allowClear={false}
							style={{ width: 120 }}
							size="small"
							value={moment(startDate)}
							onChange={handleStartDateChange}
							// picker="week"
						/>
						<DatePicker
							allowClear={false}
							style={{ width: 120 }}
							size="small"
							value={moment(endDate)}
							onChange={handleEndDateChange}
							// picker="week"
						/>
						<Button
							style={{ float: "right" }}
							onClick={() => {
								doMerge
									? setConfig({
											...config,
											seriesField: "name",
									  })
									: setConfig({
											...config,
											seriesField: "",
									  });

								// console.log("view", view, configGlobal);
								setDoMerge(!doMerge);
							}}>
							{doMerge ? "Split" : "Merge"}
						</Button>
						<FullscreenOutlined
							onClick={() => {
								setFullScreen(!fullScreen);
							}}
						/>
					</Space>
				}>
				<Space style={{ marginBottom: "30px" }} size="large">
					<div>
						<Typography.Title
							level={5}
							style={{
								margin: 0,
								wordBreak: "break-word",
							}}>
							total characters
						</Typography.Title>{" "}
						<Typography.Text>{totalCharacters}</Typography.Text>
					</div>
					<div>
						<Typography.Title
							level={5}
							style={{
								margin: 0,
								wordBreak: "break-word",
							}}>
							total credits
						</Typography.Title>{" "}
						<Typography.Text>{totalCredits}</Typography.Text>
					</div>
					<div>
						<Typography.Title
							level={5}
							style={{
								margin: 0,
								wordBreak: "break-word",
							}}>
							total requests
						</Typography.Title>{" "}
						<Typography.Text>{totalRequests}</Typography.Text>
					</div>
				</Space>

				{!Object.values(props.projectRequestMap)?.length ? (
					<div className="empty-state">
						No data available
						<div>Annotate assets to see the evolution</div>
					</div>
				) : (
					<Column {...config} />
				)}
			</Card>
		</>
	);
};

const mapState = (state: RootState) => {
	return {
		projects_map: state.overview.projects_map,
		projectRequestMap: state.overview.projectRequestsMap,
		user_projects: state.overview.user_projects_map,
		staffDataUsers: state.admin.staffData.users,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ProjectsRequestsProps = PropsFromRedux & ProjectsRequestsOwnProps;

export default connector(ProjectsRequests);
