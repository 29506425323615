import { PayloadAction } from "@reduxjs/toolkit";
import { OverviewData, OverviewReducer } from "../../modules/types/overview";

export const overviewData = (
	state: OverviewReducer,
	action: PayloadAction<OverviewData[]>
): OverviewReducer => {
	return {
		...state,
		overall_data: action.payload,
	};
};

export const overviewUserData = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		user_projects_map: {
			...state.user_projects_map,
			...action.payload,
		},
	};
};

export const overviewUserActive = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		active: action.payload,
	};
};

export const overviewDataSets = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		datasetIds_map: action.payload,
	};
};

export const overviewGroups = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		groupIds_map: action.payload,
	};
};

export const overviewAnnotationGroups = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		groupAnn_map: action.payload,
	};
};

export const overviewDictionnaries = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		dictionnaries_map: action.payload,
	};
};

export const overviewProjects = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		projects_map: {
			...state.projects_map,
			...action.payload,
		},
	};
};

export const setEvolution = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	// console.log("setEvolution action.payload", action.payload);
	return {
		...state,
		projectRequestsMap: {
			...state.projectRequestsMap,
			...action.payload,
		},
	};
};

export const overviewCampaign = (
	state: OverviewReducer,
	action: PayloadAction<any>
): OverviewReducer => {
	return {
		...state,
		campaign_map: action.payload,
	};
};
