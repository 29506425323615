import { Space, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import "./view-header.style.less";

const ViewHeader = (props: ViewHeaderProps) => {
	let customStyle = props.style;
	if (typeof customStyle != "object") customStyle = {};
	return (
		<>
			{props.fixed ? <div style={{ height: "40px" }}></div> : null}

			<div
				className="common-view-header"
				style={{
					// margin: "40ch auto",
					position: props?.fixed ? "fixed" : "inherit",
					width: props.fixed ? "calc(100vw - 310px)" : "100%",
					// textAlign: "center",
					...customStyle,
				}}>
				<Space direction="vertical" size="small">
					{props.subtitle ? (
						<Typography.Text type="secondary">
							{props.subtitle}
						</Typography.Text>
					) : null}
					<Title
						level={props.level ? props.level : 3}
						style={{
							margin: "0px",
							marginTop: "4px",
							lineHeight: "21px",
						}}>
						{props.title}
					</Title>
				</Space>
				<Space style={{ float: "right" }}>
					{props.extra ? <div>{props.extra}</div> : null}
				</Space>
			</div>
		</>
	);
};

const mapState = (state: RootState) => {
	return {
		workspace: state.workspace,
	};
};

type OwnSiderProps = {
	title: string;
	subtitle?: string;
	fixed?: boolean;
	style?: object;
	extra?: JSX.Element;
	level?: 1 | 2 | 3 | 4 | 5;
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ViewHeaderProps = PropsFromRedux & OwnSiderProps;

export default connector(ViewHeader);
