/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import RoutesManager from "./views/routes/routes-manager.component";
import axios from "axios";
import { ConfigProvider } from "antd";

import "./App.less";

const prod = "https://api.back-office.lettria.com/app/";
const preprod = "https://preprod.api.back-office.lettria.com/app";
const dev = "http://localhost:4895/app";

const localhost = "http://localhost:4895/app/";

axios.defaults.baseURL = `${prod}`;

// Hide all findDOMNode related errors caudes by ant-d with React StrictMode
const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
	if (
		// process.env.NODE_ENV === "development" &&
		typeof errObj.message === "string" &&
		args.includes("findDOMNode")
	) {
		return;
	}
	consoleError(errObj, ...args);
};
// console.warn = (errObj, ...args) => {
// 	if (
// 		// process.env.NODE_ENV === "development" &&
// 		typeof errObj.message === "string" &&
// 		args.includes("findDOMNode")
// 	) {
// 		return;
// 	}
// 	consoleError(errObj, ...args);
// };

export class App extends Component {
	render() {
		return (
			<ConfigProvider
				getPopupContainer={(triggerNode: HTMLElement | undefined) =>
					(triggerNode?.parentNode as HTMLElement) || document.body
				}
			>
				<RoutesManager />
			</ConfigProvider>
		);
	}
}
