import { StaffUser } from "modules/types/admin";
import { format } from "date-fns";

export class DemoListService {
	public columns: any = [];

	constructor() {
		this.columns = [
			{
				title: "userId",
				dataIndex: "userId",
				key: "userId",
			},
			{
				title: "createdAt",
				dataIndex: "createdAt",
				render: (date: string) => (
					<>{format(Date.parse(date), "yyyy-MM-dd")}</>
				),
				sorter: (a: StaffUser, b: StaffUser) =>
					new Date(a.lastConnection!).getTime() -
					new Date(b.lastConnection!).getTime(),
			},
			{
				title: "text",
				dataIndex: "text",
			},
		];
	}

	public getColumns = () => {
		return this.columns;
	};
}
