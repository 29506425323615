import { Analytics, Projects } from "modules/types/overview";
import { RequestService } from "services/request.service";

const requestService: RequestService = new RequestService();

export class RetrieveOverviewApi extends RequestService {
	public retrieveOverviewUserApi = async (
		id: string | undefined
	): Promise<any> => {
		const result: any = await requestService.request(
			"auth/retrieve-user-usage",
			"POST",
			{ userId: id }
		);
		type myType = {
			[key: string]: any;
		};
		var myObj: myType = {};
		for (let i = 0; i < result.userProjects.length; i++) {
			myObj[result.userProjects[i]._id] = result.userProjects[i];
		}
		return myObj;
	};

	protected getEvolutionApi = async (
		projectIds: string[],
		evolutionFilter: string,
		startDate: Date,
		endDate: Date,
		mergeProjects: boolean = false
	) => {
		const result = await this.request("auth/project-requests", "POST", {
			projectIds,
			filter: evolutionFilter,
			start: new Date(startDate).getTime(),
			end: new Date(endDate).getTime(),
			mergeProjects,
		});
		return result;
	};
}
