import { Layout } from "antd";
import "./styles/login.style.less";
import { useEffect } from "react";

export interface Credentials {
	email: string | null;
	password: string | null;
	rememberMe: boolean;
}

export const Login = () => {
	// const authService: AuthService = new AuthService();
	// const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		window.location.replace(
			"https://lettria.auth.eu-west-3.amazoncognito.com/login?client_id=2qd9nn8s895kue7o54dqohpdku&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://back-office.lettria.com/auth/cognito"
		);
	}, []);

	return (
		<Layout className="login-container">
			<img
				className="login-logo"
				alt=""
				src="/images/logo.svg"
				style={{ top: "calc(50vh - 30px)" }}
			/>
		</Layout>
	);

	// const onFinish = async (credentials: Credentials): Promise<void> => {
	// 	try {
	// 		if (credentials.email && credentials.password) {
	// 			await authService.signIn(
	// 				credentials.email,
	// 				credentials.password,
	// 				credentials.rememberMe
	// 			);
	// 		}
	// 	} catch (error) {
	// 		console.error(error);
	// 		setErrorMessage("INVALID_CREDENTIALS_ERROR");
	// 		setTimeout(() => {
	// 			setErrorMessage(null);
	// 		}, 3 * 1000);
	// 	}
	// };

	// const onFinishFailed = (
	// 	errorInfo: ValidateErrorEntity<Credentials>
	// ): void => {
	// 	console.error("Failed:", errorInfo);
	// };

	// return (
	// 	<Layout className="login-container">
	// 		<img className="login-logo" alt="" src="/images/logo.svg" />
	// 		<div className="login-card">
	// 			{/* {errorMessage ? (
	// 				<>
	// 					<Alert message={errorMessage} type="error"></Alert>
	// 					<Divider />
	// 				</>
	// 			) : null} */}
	// 			<Form
	// 				name="basic"
	// 				layout="vertical"
	// 				initialValues={{ remember: true }}
	// 				onFinish={onFinish}
	// 				onFinishFailed={onFinishFailed}
	// 				autoComplete="off"
	// 				style={{ width: "400px" }}
	// 			>
	// 				<Form.Item
	// 					name="email"
	// 					rules={[
	// 						{
	// 							required: true,
	// 							message: "Please input your email!",
	// 						},
	// 					]}
	// 				>
	// 					<Input
	// 						prefix={
	// 							<UserOutlined className="site-form-item-icon" />
	// 						}
	// 						placeholder="Email / Username"
	// 					/>
	// 				</Form.Item>

	// 				<Form.Item
	// 					name="password"
	// 					rules={[
	// 						{
	// 							required: true,
	// 							message: "Please input your password!",
	// 						},
	// 					]}
	// 				>
	// 					<Input.Password
	// 						prefix={
	// 							<LockOutlined className="site-form-item-icon" />
	// 						}
	// 					/>
	// 				</Form.Item>

	// 				{/* <Form.Item name="remember" valuePropName="checked">
	// 					<Checkbox>Remember me</Checkbox>
	// 				</Form.Item> */}
	// 				<div className="or-signup">
	// 					Don't have an account? <Link to="/signup">Sign Up</Link>
	// 				</div>
	// 				<Button
	// 					type="primary"
	// 					htmlType="submit"
	// 					className="button-submit"
	// 				>
	// 					Sign in
	// 				</Button>
	// 			</Form>
	// 		</div>
	// 	</Layout>
	// );
};
