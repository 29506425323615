import { UserReducer, UserData, UserItems } from "modules/types/user";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";

export const setUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	let data = formatKnowledgeBeforeRedux(action.payload) as UserData;
	return {
		...state,
		data,
		loggedIn: true,
		init: true,
	};
};

export const deleteUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	return {
		...state,
		data: null,
		loggedIn: false,
	};
};

export const setLoggedIn = (
	state: UserReducer,
	action: PayloadAction<boolean>
): UserReducer => {
	return {
		...state,
		loggedIn: action.payload,
		init: true,
	};
};

export const addRessourceToUser = (
	state: UserReducer,
	action: PayloadAction<{
		userKey: string;
		ressource: any;
	}>
) => {
	let { userKey, ressource } = action.payload;
	let data = { ...state.data } as UserData;

	let userItems: UserItems = {
		id: ressource._id,
		ownerType: ressource.ownerType,
		userType: "Admin",
		owner: ressource.owner,
	};

	switch (userKey) {
		case "projectIds":
			data.projectIds?.push(userItems);
			break;
		case "dictionaryIds":
			data.dictionaryIds?.push(userItems);
			break;
		case "annotationGroupIds":
			data.annotationGroupIds?.push(userItems);
			break;
		case "annotationCampaignIds":
			data.annotationCampaignIds?.push(userItems);
			break;
		case "ontologyIds":
			data.ontologyIds?.push(userItems);
			break;
		case "patternIds":
			data.patternIds?.push(userItems);
			break;
	}

	return {
		...state,
		data,
	};
};

export const deleteRessourceFromUser = (
	state: UserReducer,
	action: PayloadAction<{
		userKey: string;
		ressourceId: any;
	}>
) => {
	let { userKey, ressourceId } = action.payload;
	let data = { ...state.data } as UserData;

	switch (userKey) {
		case "projectIds":
			data.projectIds = data.projectIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
		case "dictionaryIds":
			data.dictionaryIds = data.dictionaryIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
		case "annotationGroupIds":
			data.annotationGroupIds = data.annotationGroupIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
		case "annotationCampaignIds":
			data.annotationCampaignIds = data.annotationCampaignIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
		case "ontologyIds":
			data.ontologyIds = data.ontologyIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
		case "patternIds":
			data.patternIds = data.patternIds.filter(
				(item: UserItems) => item.id !== ressourceId
			);
			break;
	}

	return {
		...state,
		data,
	};
};
