/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "../store";

export const overviewData = (user: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_DATA",
		payload: user,
	});
};

export const overviewUserData = (user_projects_map: any): AnyAction => {
	// console.log("overviewUserData", user_projects_map);
	return store.dispatch({
		type: "OVERVIEW_USER_DATA",
		payload: user_projects_map,
	});
};

export const overviewUserActive = (active: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_USER_ACTIVE",
		payload: active,
	});
};

export const overviewDataSets = (datasets: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_DATASETS_IDS",
		payload: datasets,
	});
};

export const overviewGroups = (groups: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_GROUP_IDS",
		payload: groups,
	});
};

export const overviewAnnotationGroups = (ann_groups: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_ANNOTATION_GRPS",
		payload: ann_groups,
	});
};

export const overviewDictionnaries = (dictionnaries: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_DICTIONNARIES",
		payload: dictionnaries,
	});
};

export const overviewProjects = (projects: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_PROJECTS",
		payload: projects,
	});
};

export const overviewCampaign = (campaign: any): AnyAction => {
	return store.dispatch({
		type: "OVERVIEW_CAMPAIGN",
		payload: campaign,
	});
};

export const setEvolution = (res: any) => {
	return store.dispatch({
		type: "SET_EVOLUTION_DATA",
		payload: res,
	});
};
