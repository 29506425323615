import { setLoggedIn, setUser } from "store/user/user-actions";
import { AuthApi } from "../api/auth.api.service";
import { Credentials as SignupCredentials } from "../../../signup/signup.component";
import { CognitoTokenResponse } from "modules/types/user";

export class AuthStore extends AuthApi {
	/**
	 * Thunks that call a dispatch to make api call
	 * @param email
	 * @param password
	 * @returns response from sign in api request {token: string, user: UserData}
	 */
	public signInThunk = async (
		email: string,
		password: string,
		rememberMe: boolean
	) => {
		const data = await this.signInApi({ email, password });
		const res = data;
		if (!res.token || !res.user) return false;
		// if (rememberMe)
		localStorage.setItem("LettriaSessionKey", res.token);
		setUser(res.user);
		setLoggedIn(true);
	};

	public signUpThunk = async (credentials: SignupCredentials) => {
		const data = await this.signUpApi(credentials);
		const res = data;
		if (!res.token || !res.user) return false;
		// if (rememberMe)
		localStorage.setItem("LettriaSessionKey", res.token);
		setUser(res.user);
		setLoggedIn(true);
	};

	/**
	 * Sign out function that remove token from localStorage
	 */
	public signOutThunk = async () => {
		localStorage.removeItem("LettriaSessionKey");
		localStorage.removeItem("LettriaRefreshToken");
		localStorage.removeItem("LettriaIdToken");
		localStorage.removeItem("LettriaAccessToken");
		// setLoggedIn(false);
		window.location.reload();

		// await routesService.restoreSession();
		return true;
	};

	public signInCognitoThunk = async (code: string) => {
		try {
			const tokenResponse = await this.signInCognitoApi(code);
			// console.log({ tokenResponse });
			if (tokenResponse?.access_token)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.access_token
				);
			if (tokenResponse?.id_token)
				localStorage.setItem("LettriaIdToken", tokenResponse.id_token);
			if (tokenResponse?.refresh_token)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refresh_token
				);

			const getSelfResponse = await this.getSelfApi(
				tokenResponse?.id_token!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
				setLoggedIn(true);
			}
		} catch (e) {
			console.error(e);
			window.location.replace(
				"https://lettria.auth.eu-west-3.amazoncognito.com/login?client_id=2qd9nn8s895kue7o54dqohpdku&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://back-office.lettria.com/auth/cognito"
			);
		}

		return true;
	};

	public restoreSessionThunk = async () => {
		try {
			let currentIdToken = localStorage.getItem("LettriaIdToken");
			if (!currentIdToken) {
				setLoggedIn(false);
				return true;
			}
			let tokenResponse: CognitoTokenResponse =
				await this.restoreSessionApi();
			if (tokenResponse?.access_token)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.access_token
				);
			if (tokenResponse?.id_token)
				localStorage.setItem("LettriaIdToken", tokenResponse.id_token);
			if (tokenResponse?.refresh_token)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refresh_token
				);

			const getSelfResponse = await this.getSelfApi(
				tokenResponse?.id_token!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
				setLoggedIn(true);
			}
		} catch (error: unknown) {
			// alert(error);
			setLoggedIn(false);
		}
	};
}
