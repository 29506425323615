import { Layout, Spin, Table } from "antd";
import ViewHeader from "common/components/view-header/view-header";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { UserListService } from "./services/settings-view-list-users.service";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { StaffUser } from "modules/types/admin";
import { Input } from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

const SettingsViewListUsers = (props: SettingsViewListUsersProps) => {
	const userlistservice: UserListService = new UserListService();
	const { staffDataUsers } = props;
	var resultArray = Object.values(staffDataUsers);
	const [searchedText, setSearchedText] = useState<any>("");
	const [filteredData, setFilteredData] = useState(resultArray);

	useEffect(() => {
		if (staffDataUsers) {
			setFilteredData(Object.values(staffDataUsers));
		}
	}, [staffDataUsers]);

	useEffect(() => {
		if (!(searchedText.trim().length === 0)) {
			setFilteredData(
				resultArray.filter((item) => item.email.includes(searchedText))
			);
		} else {
			setFilteredData(resultArray);
		}
	}, [searchedText]);

	return (
		<Layout>
			<ViewHeader title={"List Users"} subtitle={"Manage"} />
			<div style={{ padding: "32px" }}>
				{Array.isArray(filteredData) && filteredData.length ? (
					// {Array.isArray(filteredData) ? (
					<>
						<Input.Search
							onPressEnter={(value) => {
								setSearchedText(value);
							}}
							onChange={(e) => {
								setSearchedText(e.target.value.trim());
							}}
							placeholder={"Search by email"}
						/>
						<Table
							dataSource={filteredData}
							columns={userlistservice.getColumns()}
						/>
					</>
				) : !searchedText ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
						}}>
						<Spin size="large" />
					</div>
				) : (
					<>
						<Input.Search
							onPressEnter={(value) => {
								setSearchedText(value);
							}}
							onChange={(e) => {
								setFilteredData(resultArray);

								setSearchedText(e.target.value.trim());
							}}
							placeholder={"Search by email"}
						/>
						<Table
							dataSource={filteredData}
							columns={userlistservice.getColumns()}
						/>
					</>
				)}
			</div>
		</Layout>
	);
};

const mapState = (state: RootState) => {
	return {
		userData: state.user.data,
		staffDataUsers: state.admin.staffData.users,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewListUsersProps = PropsFromRedux;

export default connector(SettingsViewListUsers);
