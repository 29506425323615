import { RetrieveStaffDataApi } from "services/retrieve-data/retrieve-staff-data/api/retrieve-staffdata.api.service";
import {
	overviewData,
	overviewUserActive,
	overviewUserData,
	overviewDataSets,
	overviewGroups,
	overviewProjects,
	overviewDictionnaries,
	overviewCampaign,
	overviewAnnotationGroups,
	setEvolution,
} from "store/overview/overview-action";
import { RetrieveOverviewApi } from "../api/retrieve-overview.api.service";
import { compareAsc } from "date-fns";

export class RetrieveOverviewStore extends RetrieveOverviewApi {
	/**
	 * Retrieve all notification owned by user
	 * @returns null
	 */

	// public retrieveOverview = async (_res: any, userId: string = "") => {
	// 	await this.retrieveOverviewUserData(_res);
	// };

	public getEvolution = async (
		projectIds: string[],
		evolutionFilter: string,
		startDate: Date,
		endDate: Date,
		mergeProjects: boolean = false
	) => {
		const result = await this.getEvolutionApi(
			projectIds,
			evolutionFilter,
			startDate,
			endDate,
			mergeProjects
		);
		setEvolution(result);
		return result;
	};

	public retrieveOverviewUserData = async (_res: any) => {
		var user_projects_map: any = {};
		var projects_map: any = {};
		await Promise.all(
			Object.keys(_res.users).map(async (key) => {
				var _projects = await this.retrieveOverviewUserApi(key);
				user_projects_map = Object.assign(user_projects_map, {
					[key]: _projects,
				});
				if (!Object.values(projects_map).includes(_projects)) {
					projects_map = Object.assign(projects_map, _projects);
				}
			})
		);
		overviewProjects(projects_map);
		overviewUserData(user_projects_map);
	};

	public retrieveOverviewAnnotationGrps = async (_res: any) => {
		var annGrps_map: any = {};
		Object.keys(_res.users).map(async (key) => {
			Object.values(_res.users[key].stats.ngroupAnnotation).map(
				async () => {
					const element = Object.values(
						_res.users[key].stats.ngroupAnnotation
					);
					if (!Object.values(annGrps_map).includes(element)) {
						annGrps_map = Object.assign(annGrps_map, element);
					}
				}
			);
		});
		overviewAnnotationGroups(annGrps_map);
	};

	public retrieveOverviewDictionnaries = async (_res: any) => {
		var dictionnaries_map: any = {};
		Object.keys(_res.users).map(async (key) => {
			Object.values(_res.users[key].stats.ndictionary).map(async () => {
				const element = Object.values(
					_res.users[key].stats.ndictionary
				);
				if (!Object.values(dictionnaries_map).includes(element)) {
					dictionnaries_map = Object.assign(
						dictionnaries_map,
						element
					);
				}
			});
		});
		overviewDictionnaries(dictionnaries_map);
	};

	public retrieveOverviewCampaign = async (_res: any) => {
		var campaign_map: any = {};
		Object.keys(_res.users).map(async (key) => {
			Object.values(_res.users[key].stats.ncampaign).map(async () => {
				const element = Object.values(_res.users[key].stats.ncampaign);
				if (!Object.values(campaign_map).includes(element)) {
					campaign_map = Object.assign(campaign_map, element);
				}
			});
		});
		overviewCampaign(campaign_map);
	};

	public retrieveOverviewActive = async (_res: any) => {
		var active: number = 0;
		const today = new Date();
		var limit = new Date();
		Object.keys(_res.users).map(async (key) => {
			if (
				compareAsc(
					new Date(_res.users[key].lastConnection),
					new Date(limit.setDate(today.getDate() - 10))
				) == 1
			) {
				active += 1;
			}
		});
		overviewUserActive(active);
	};

	public retrieveOverviewDataSets = async (_res: any) => {
		var datasetIds_map: any = {};
		Object.keys(_res.users).map(async (key) => {
			if (_res.users[key].datasetIds) {
				Object.values(_res.users[key].datasetIds).map(
					async (element) => {
						if (!Object.values(datasetIds_map).includes(element)) {
							datasetIds_map = Object.assign(datasetIds_map, {
								[Object.values(
									_res.users[key].datasetIds
								).indexOf(element)]: element,
							});
						}
					}
				);
			}
		});
		overviewDataSets(datasetIds_map);
	};

	public retrieveOverviewGroups = async (_res: any) => {
		// console.log(_res);
		var groupIds_map: any = {};
		Object.keys(_res.users).map(async (key) => {
			if (_res.users[key].groupIds) {
				Object.values(_res.users[key].groupIds).map(async (element) => {
					if (!Object.values(groupIds_map).includes(element)) {
						groupIds_map = Object.assign(groupIds_map, {
							[Object.values(_res.users[key].groupIds).indexOf(
								element
							)]: element,
						});
					}
				});
			}
		});
		overviewGroups(groupIds_map);
	};
}
