import { AdminReducer } from "modules/types/admin";
import { AdminStore } from "./store/admin.store.service";

const staff: AdminReducer = {
	staffData: {
		users: {},
		demo: {},
	},
};

export class AdminService extends AdminStore {
	public setWorkspace = () => {
		this.setWorkspaceStore("admin");
	};

	public setStaffData = () => {
		this.setStaffStore(staff);
	};
}
