import { Column, Line } from "@ant-design/charts";
import {
	Button,
	Card,
	Col,
	Input,
	InputNumber,
	Layout,
	Row,
	Space,
	Spin,
	Statistic,
	Table,
	Typography,
} from "antd";
import ViewHeader from "common/components/view-header/view-header";
import { format } from "date-fns";
import { useState, useEffect, SetStateAction } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { RetrieveStaffDataApi } from "services/retrieve-data/retrieve-staff-data/api/retrieve-staffdata.api.service";
import { RootState } from "store/store";
import OverviewChart from "./components/overview-chart.component";
import { OverviewApi } from "./services/api/settings-overview.api.serivce";
import { OverviewService } from "./services/settings-overview.service";
import ProjectRequests from "common/components/projects-requests/projects-requests.component";
import { UserData, UserItems } from "modules/types/user";
import { LeftCircleOutlined } from "@ant-design/icons";

const SettingsOverview = (props: OverviewProps) => {
	const [usersMetadata, setUsersMetadata] = useState<{
		dictionaries: string[];
		projects: string[];
		patterns: string[];
		campaigns: string[];
		group: string[];
		knowledge: string[];
	}>({
		dictionaries: [],
		projects: [],
		patterns: [],
		campaigns: [],
		group: [],
		knowledge: [],
	});

	useEffect(() => {
		const _data = getUsersMetadata(Object.values(props.users));
		setUsersMetadata(_data);
	}, [props.users]);

	const getUsersMetadata = (users: UserData[]) => {
		let dictionaries: string[] = [],
			projects: string[] = [],
			patterns: string[] = [],
			campaigns: string[] = [],
			group: string[] = [],
			knowledge: string[] = [];

		for (let user of users) {
			user?.groupIds?.forEach((item: UserItems) => {
				if (!group.includes(item.id)) group.push(item.id);
			});
			user?.projectIds?.forEach((item: UserItems) => {
				if (!projects.includes(item.id)) projects.push(item.id);
			});
			user?.patternIds?.forEach((item: UserItems) => {
				if (!patterns.includes(item.id)) patterns.push(item.id);
			});
			user?.dictionaryIds?.forEach((item: UserItems) => {
				if (!dictionaries.includes(item.id)) dictionaries.push(item.id);
			});
			user?.knowledgeIds?.forEach((item: UserItems) => {
				if (!knowledge.includes(item.id)) knowledge.push(item.id);
			});
			user?.annotationCampaignIds?.forEach((item: UserItems) => {
				if (!campaigns.includes(item.id)) campaigns.push(item.id);
			});
		}
		return {
			dictionaries,
			projects,
			patterns,
			campaigns,
			group,
			knowledge,
		};
	};

	return (
		<Layout>
			<ViewHeader title={"Overview"} />
			<div style={{ padding: "15px" }}>
				<Space direction="vertical" style={{ width: "100%" }}>
					<Card>
						<Row key={"Row1"}>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Dictionaries :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.dictionaries?.length}
								</Typography.Text>
							</Col>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Projects :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.projects?.length}
								</Typography.Text>
							</Col>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Patterns :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.patterns?.length}
								</Typography.Text>
							</Col>
						</Row>
						<Row key={"Row2"}>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Campaigns :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.campaigns?.length}
								</Typography.Text>
							</Col>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Groups :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.group?.length}
								</Typography.Text>
							</Col>
							<Col span={8}>
								<Typography.Title
									level={5}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Knowledge :
								</Typography.Title>
								<Typography.Text>
									{usersMetadata.knowledge?.length}
								</Typography.Text>
							</Col>
						</Row>
					</Card>
					<ProjectRequests projectIds={usersMetadata?.projects} />
				</Space>
			</div>
		</Layout>
	);
};

const mapState = (state: RootState) => {
	return {
		users: state.admin?.staffData?.users || [],
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type OverviewProps = PropsFromRedux;

export default connector(SettingsOverview);
