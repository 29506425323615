/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "../store";
import { AdminReducer } from "../../modules/types/admin";
import { Subscription } from "modules/types/subscription";

export const setStaffData = (user: AdminReducer): AnyAction => {
	return store.dispatch({
		type: "SET_STAFF_DATA",
		payload: user,
	});
};

export const updateUserSubs = (
	subs: { subs: Subscription[] },
	userId: string
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_USER_SUBS",
		payload: { subs, userId },
	});
};

export const deleteSub = (userId: string, request: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_A_SUB",
		payload: { userId, request },
	});
};

export const modifySub = (id: any, type: string, sub: string): AnyAction => {
	return store.dispatch({
		type: "MODIFY_A_SUB",
		payload: { id, type, sub },
	});
};
