import { Route, Routes, Navigate } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import SettingsViewUserInfos from "./settings-view-user-infos/settings-view-user-infos.component";
import SettingsViewUserCharts from "./settings-view-user-charts/settings-view-user-charts.component";

const SettingsViewUserRoutes = (props: SettingsViewUserRoutesProps) => {
	return (
		<Routes>
			<Route path="usage" element={<SettingsViewUserCharts />} />
			<Route path="/" element={<SettingsViewUserInfos />} />
			<Route path="*" element={<Navigate replace to="usage" />} />
		</Routes>
	);
};

const mapState = (state: RootState) => {
	return {
		// settingsMap: state.settings.map,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewUserRoutesProps = PropsFromRedux;

export default connector(SettingsViewUserRoutes);
