import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { RouterWrapper } from "./router-wrapper/router-wrapper.component";
import { Login } from "../public/login/login.component";
import { PrivateLayout } from "./private-layout/private-layout.component";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import CognitoAuthRelay from "views/public/cognito-auth-relay/cognito-auth-relay.component";
import { AuthService } from "views/public/auth/services/auth.service";

const RoutesManager = (props: RoutesManagerProps) => {
	const authService: AuthService = new AuthService();

	useEffect(() => {
		authService.restoreSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Router>
			<Routes>
				<Route element={<RouterWrapper />}>
					{props.init ? (
						props.loggedIn ? (
							<>
								<Route path="/*" element={<PrivateLayout />} />
								<Route
									path="*"
									element={
										<>
											<Navigate replace to="/" />
										</>
									}
								/>
							</>
						) : (
							<>
								<Route
									path="/auth/cognito"
									element={<CognitoAuthRelay />}
								/>
								<Route path="/login" element={<Login />} />
								<Route
									path="*"
									element={
										<>
											<Navigate replace to="/login" />
										</>
									}
								/>
							</>
						)
					) : (
						<></>
					)}
				</Route>
			</Routes>
		</Router>
	);
};

const mapState = (state: RootState) => ({
	loggedIn: state.user.loggedIn as boolean,
	init: state.user.init as boolean,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(RoutesManager);
