import axios from "axios";
import {
	CognitoTokenResponse,
	UserData,
	UserResponse,
} from "modules/types/user";
import { RequestService } from "services/request.service";

export class AuthApi extends RequestService {
	public signInApi = async (body: any): Promise<UserResponse> => {
		let res: UserResponse = await this.request("auth/login", "POST", body);
		return res;
	};

	public signUpApi = async (body: any): Promise<UserResponse> => {
		let res: UserResponse = await this.request("auth/signup", "POST", body);
		return res;
	};

	public signInCognitoApi = async (
		code: string
	): Promise<CognitoTokenResponse> => {
		let res: CognitoTokenResponse = await this.request(
			"aws/cognito-auth-token" + `?code=${code}`,
			"GET",
			null
		);
		return res;
	};

	public getSelfApi = async (idToken: string): Promise<UserData> => {
		let res: UserData = await this.request(
			"auth/get-self",
			"GET",
			null,
			idToken
		);
		return res;
	};

	public restoreSessionApi = async (): Promise<CognitoTokenResponse> => {
		try {
			let res: any = await axios({
				url: "aws/refresh-token",
				method: "GET",
				headers: {
					Authorization: `LettriaRefreshToken ${localStorage.getItem(
						"LettriaRefreshToken"
					)}`,
				},
			});

			return res;
		} catch (error) {
			console.error(error);
		}
		return null!;
	};
}
