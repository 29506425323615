import { Layout, Space, Card, Table, Typography } from "antd";
import ViewHeader from "common/components/view-header/view-header";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { DemoListService } from "./services/settings-view-list-demos.service";

const SettingsViewListDemo = (props: SettingsViewListDemoProps) => {
	const demolistservice: DemoListService = new DemoListService();
	const { staffDataDemo } = props;
	//console.log("staffDataDemo", staffDataDemo);

	if (staffDataDemo.data == null) {
		return <></>;
	} else {
		var resultArray = Object.values(staffDataDemo.data);

		return (
			<Layout>
				<ViewHeader title={"Demo"} subtitle={"Manage"} />
				<div style={{ padding: "32px" }}>
					<Card>
						<Space
							size={80}
							direction="vertical"
							style={{ width: "100%" }}>
							<Space size={70}>
								<div>
									<Typography.Title
										level={4}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										Total calls
									</Typography.Title>
									<Typography.Text>
										{staffDataDemo.total_calls}
									</Typography.Text>
								</div>
								<div>
									<Typography.Title
										level={4}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										Total users
									</Typography.Title>
									<Typography.Text>
										{staffDataDemo.total_users}
									</Typography.Text>
								</div>
							</Space>
							<div>
								<Typography.Title
									level={4}
									style={{
										margin: 0,
										wordBreak: "break-word",
									}}>
									Data
								</Typography.Title>
								<Table
									dataSource={
										resultArray as readonly object[]
									}
									columns={demolistservice.getColumns()}
								/>
							</div>
						</Space>
					</Card>
				</div>
			</Layout>
		);
	}
};

const mapState = (state: RootState) => {
	return {
		userData: state.user.data,
		staffDataDemo: state.admin.staffData.demo,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewListDemoProps = PropsFromRedux;

export default connector(SettingsViewListDemo);
