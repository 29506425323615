import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";

// const settingsViewService: SettingsViewService =
// 	new SettingsViewService();

const SettingsView = (props: SettingsViewProps) => {
	if (!props.userData) return <></>;
	return (
		<Layout>
			{/*<Sider
				data={getSiderData()}
				item={props.userData}
				page={"settings"}
				workspace="admin"
			/>*/}
			<Layout>
				<Outlet />
			</Layout>
		</Layout>
	);
};

const mapState = (state: RootState) => {
	return {
		userData: state.user.data,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewProps = PropsFromRedux;

export default connector(SettingsView);
