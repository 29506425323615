import { AnyAction } from "@reduxjs/toolkit";
import * as Type from "../../modules/types/overview";
import {
	overviewAnnotationGroups,
	overviewCampaign,
	overviewData,
	overviewDataSets,
	overviewDictionnaries,
	overviewGroups,
	overviewProjects,
	overviewUserActive,
	overviewUserData,
	setEvolution,
} from "./overview-methods";
import {
	OverviewDataActionType,
	OverviewUserDataActionType,
} from "../../modules/types/overview/store";

const initialData: Type.OverviewReducer = {
	overall_data: [],
	user_projects_map: {},
	active: 0,
	datasetIds_map: {},
	groupIds_map: {},
	groupAnn_map: {},
	dictionnaries_map: {},
	projects_map: {},
	campaign_map: {},
	projectRequestsMap: {},
};

export const OverviewReducer = (
	state = initialData,
	action: AnyAction
): Type.OverviewReducer => {
	switch (action.type) {
		case "OVERVIEW_DATA": {
			return overviewData(state, action as OverviewDataActionType);
		}
		case "OVERVIEW_USER_DATA": {
			return overviewUserData(
				state,
				action as OverviewUserDataActionType
			);
		}
		case "OVERVIEW_USER_ACTIVE": {
			return overviewUserActive(state, action as OverviewDataActionType);
		}
		case "OVERVIEW_DATASETS_IDS": {
			return overviewDataSets(state, action as OverviewDataActionType);
		}
		case "OVERVIEW_GROUP_IDS": {
			return overviewGroups(state, action as OverviewDataActionType);
		}
		case "OVERVIEW_ANNOTATION_GRPS": {
			return overviewAnnotationGroups(
				state,
				action as OverviewDataActionType
			);
		}
		case "OVERVIEW_DICTIONNARIES": {
			return overviewDictionnaries(
				state,
				action as OverviewDataActionType
			);
		}
		case "OVERVIEW_CAMPAIGN": {
			return overviewCampaign(state, action as OverviewDataActionType);
		}
		case "OVERVIEW_PROJECTS": {
			return overviewProjects(state, action as OverviewDataActionType);
		}
		case "SET_EVOLUTION_DATA": {
			return setEvolution(state, action as OverviewDataActionType);
		}
		default: {
			break;
		}
	}
	return state;
};
