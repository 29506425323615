import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import "./styles/router-layout.style.less";

export const RouterWrapper = () => {
	return (
		<Layout className="router-layout">
			<Outlet />
		</Layout>
	);
};
