import { combineReducers } from "redux";
import { userReducer } from "./user/user-reducer";

import { analyticReducer } from "./analytic/analytic-reducer";
import { workspaceReducer } from "./workspace/workspace-reducer";
import { subscriptionReducer } from "./subscription/subscription-reducer";
import { AdminReducer } from "./admin/admin-reducer";
import { OverviewReducer } from "./overview/overview-reducer";

export default combineReducers({
	/**
	 * USER
	 */
	user: userReducer,
	workspace: workspaceReducer,
	admin: AdminReducer,
	overview: OverviewReducer,

	/**
	 * OTHER
	 */
	subscription: subscriptionReducer,
	analytic: analyticReducer,
});
