/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import SettingsRoutes from "./settings/settings.routes";
import { AdminService } from "./services/admin.service";
import { useEffect } from "react";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import SettingsViewRoutes from "./settings/settings-view/settings-view.routes";

const AdminRoutesComponent = (props: RoutesManagerProps) => {
	const adminService: AdminService = new AdminService();

	useEffect(() => {
		(async () => {
			/**
			 * Retrieve data needed for this workspace
			 */
			if (props.workspace.name !== "admin") {
				await adminService.setWorkspace();
			}
		})();
	}, []);

	return (
		<Routes>
			<Route>
				{/* <Route path="settings/*" element={<SettingsRoutes />} /> */}
				<Route path="settings/*" element={<SettingsViewRoutes />} />
				<Route
					path="*"
					element={
						<>
							<Navigate replace to="settings/" />
						</>
					}
				/>
			</Route>
		</Routes>
	);
};

const mapState = (state: RootState) => {
	let userRole: string = "";
	if (state.user && state.user.data)
		userRole = state.user.data?.role as string;

	return {
		role: userRole,
		user: state.user,
		workspace: state.workspace,
	};
};
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(AdminRoutesComponent);
