import { AuthStore } from "./store/auth.store.service";
import { Credentials as SignupCredentials } from "../../signup/signup.component";

export class AuthService extends AuthStore {
	/**
	 * Service function that call sign in thunks
	 * @param email
	 * @param password
	 * @param _rememberMe
	 */
	public signIn = async (
		email: string,
		password: string,
		rememberMe: boolean
	) => {
		await this.signInThunk(email, password, rememberMe);
	};

	public signUp = async (credentials: SignupCredentials) => {
		await this.signUpThunk(credentials);
	};

	/**
	 * Service function that call sign out thunks
	 */
	public signOut = async () => {
		await this.signOutThunk();
	};

	/**
	 * Service function that call sign out thunks
	 */
	public signInCognito = async (code: string) => {
		await this.signInCognitoThunk(code);
	};

	/**
	 * Service function that call sign out thunks
	 */
	public restoreSession = async () => {
		await this.restoreSessionThunk();
	};
}
