import PrivateRoutes from "./routes/private.routes";
import { Layout, Spin } from "antd";
import HeaderComponent from "./components/header/header.component";
import "./styles/layout.style.less";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { useEffect, useState } from "react";

export const PrivateLayout = () => {
	const [isRetrieve, setIsRetrieve] = useState<boolean>(false);
	const retrieveDataService: RetrieveDataService = new RetrieveDataService();

	useEffect(() => {
		(async () => {
			try {
				await retrieveDataService.retrieveStaffData();
				setIsRetrieve(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	if (!isRetrieve) return <Spin />;

	return (
		<Layout className="app-full-layout">
			<Layout className="app-layout">
				<HeaderComponent />
				<PrivateRoutes />
			</Layout>
		</Layout>
	);
};
