import { Button, Card, DatePicker, Select, Space, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import { useState, useEffect } from "react";
import { Column, DualAxes, Line } from "@ant-design/charts";
import moment from "moment";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { parse, subMonths } from "date-fns";
import { useParams } from "react-router";

type Config = {
	data: any[];
	height: number;
	xField: string;
	yField: string;
	seriesField: string | undefined;
	geometryOptions?: [
		{
			geometry: string;
		}
	];
};

type Request = {
	project: any;
	requests: any[];
};

const evolutionFilterOptions = [
	{
		value: "week",
		label: "Week",
	},
	{
		value: "month",
		label: "Month",
	},
	{
		value: "dayOfYear",
		label: "Day",
	},
];

const Evolution = (props: EvolutionProps) => {
	// const dashboardService: AnnotationCampaignViewDashboardService =
	// 	new AnnotationCampaignViewDashboardService();
	const currentTime = new Date();

	const [view, setView] = useState(false);
	const [config, setConfig] = useState<Config>({
		data: [],
		xField: "createdAt",
		yField: "totalCredits",
		seriesField: "name",
		height: 250,
	});
	const [isEvolutionData, setIsEvolutionData] = useState(false);
	const [evolutionFilter, setEvolutionFilter] = useState("dayOfYear");
	const [startDate, setStartDate] = useState(subMonths(currentTime, 3));
	const [endDate, setEndDate] = useState(currentTime);

	const { userId } = useParams();
	const retrieveDataService: RetrieveDataService = new RetrieveDataService();
	const { projectRequestMap }: any = props;
	// const userData = props.staffDataUsers[userId as string];
	var userProjects: any = props.user_projects[userId!];

	useEffect(() => {
		var list: any[] = [];
		try {
			Object.values(userProjects).forEach((project) => {
				list.push(project);
			});
			let _projects: string[] = [];
			Object.keys(userProjects).forEach((projectIds) => {
				_projects.push(projectIds);
			});
			(async () => {
				// console.log("projects", _projects);
				await retrieveDataService
					.getEvolution(
						_projects,
						evolutionFilter,
						startDate,
						endDate
					)
					.then(() => setView(!view));
			})();
		} catch (error) {
			console.log(error);
		}
	}, [userProjects, evolutionFilter, startDate, endDate]);

	useEffect(() => {
		let _config = config;
		let data: any[] = [];

		Object.values(projectRequestMap).forEach((value) => {
			let projectRequests = value as Request;
			projectRequests.requests.forEach((request: any) => {
				if (!projectRequests?.project?.name)
					console.log("no name", projectRequests);

				data.push({
					...request,
					name: projectRequests?.project?.name,
				});
			});
		});

		_config.data = data;
		setConfig(_config);
		setIsEvolutionData(true);
		console.log("data", data);
	}, [projectRequestMap]);

	const handleStartDateChange = (v: any) => {
		setStartDate(v);
	};

	const handleEndDateChange = (v: any) => {
		setEndDate(v);
	};

	if (!isEvolutionData) return <Spin />;
	return (
		<>
			<Card
				size="small"
				title={<Title level={4}>Evolution</Title>}
				extra={
					<Space>
						<Select
							size="small"
							value={evolutionFilter}
							onChange={(value) => {
								setEvolutionFilter(value);
							}}
							bordered={false}
							style={{ width: 85 }}>
							{evolutionFilterOptions.map((option: any) => (
								<Select.Option value={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select>
						<DatePicker
							allowClear={false}
							style={{ width: 120 }}
							size="small"
							value={moment(startDate)}
							onChange={handleStartDateChange}
							// picker="week"
						/>
						<DatePicker
							allowClear={false}
							style={{ width: 120 }}
							size="small"
							value={moment(endDate)}
							onChange={handleEndDateChange}
							// picker="week"
						/>
						<Button
							style={{ float: "right" }}
							onClick={() => {
								view
									? setConfig({
											...config,
											seriesField: "name",
									  })
									: setConfig({
											...config,
											seriesField: "",
									  });

								// console.log("view", view, configGlobal);
								setView(!view);
							}}>
							Switch
						</Button>
					</Space>
				}>
				{!Object.values(props.projectRequestMap)?.length ? (
					<div className="empty-state">
						No data available
						<div>Annotate assets to see the evolution</div>
					</div>
				) : (
					<Line {...config} />
				)}
			</Card>
		</>
	);
};

const mapState = (state: RootState) => {
	return {
		projects_map: state.overview.projects_map,
		projectRequestMap: state.overview.projectRequestsMap,
		user_projects: state.overview.user_projects_map,
		staffDataUsers: state.admin.staffData.users,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type EvolutionProps = PropsFromRedux;

export default connector(Evolution);
