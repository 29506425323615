import { PayloadAction } from "@reduxjs/toolkit";
import { Subscription } from "modules/types/subscription";
import { AdminReducer, StaffData } from "../../modules/types/admin";

export const setStaffData = (
	state: AdminReducer,
	action: PayloadAction<StaffData>
): AdminReducer => {
	return {
		...state,
		staffData: {
			users: action.payload.users,
			demo: action.payload.demo,
		},
	};
};

export const updateUserSubs = (
	state: AdminReducer,
	action: PayloadAction<{ userId: string; subs: { subs: Subscription[] } }>
): AdminReducer => {
	let newState = { ...state };
	let newStaffData = { ...newState.staffData };
	let newUsers = { ...newStaffData.users };

	let { userId, subs } = action.payload;
	let sameDemos = { ...state.staffData.demo };

	subs.subs.forEach((element) => {
		if (
			element?._id !==
			newUsers[userId].stats.subs[subs.subs.indexOf(element)]?._id
		) {
			newUsers[userId].stats.subs.push(element);
		}
	});

	return {
		...state,
		staffData: {
			users: newUsers,
			demo: sameDemos,
		},
	};
	//return newState;
};

export const deleteSub = (
	state: any,
	action: PayloadAction<{ userId: any; request: string }>
): AdminReducer => {
	let newState = { ...state };
	let newStaffData = { ...newState.staffData };
	let newUsers = { ...newStaffData.users };
	let sameDemos = { ...state.staffData.demo };
	let { userId, request } = action.payload;
	let newArray: any[] = [];
	newUsers[userId.userId].stats.subs = newUsers[
		userId.userId
	].stats.subs.forEach((element: any) => {
		if (element._id !== request) {
			newArray.push(element);
		}
	});

	newUsers[userId.userId].stats.subs = newArray;

	return {
		...state,
		staffData: {
			users: newUsers,
			demo: sameDemos,
		},
	};
	//return newState;
};

export const modifySub = (
	state: any,
	action: PayloadAction<{ id: any; type: string; sub: string }>
): AdminReducer => {
	let newState = { ...state };
	let newStaffData = { ...newState.staffData };
	let newUsers = { ...newStaffData.users };
	let sameDemos = { ...state.staffData.demo };
	let { id, type, sub } = action.payload;
	// console.log("newUsers[id]", newUsers, newUsers[id.userId], id);
	newUsers[id.userId].stats.subs.forEach((element: any) => {
		if (element._id === sub) {
			element.type = type;
		}
	});

	return {
		...state,
		staffData: {
			users: newUsers,
			demo: sameDemos,
		},
	};
};
