import { RetrieveStaffDataApi } from "../api/retrieve-staffdata.api.service";
import {
	updateUserSubs,
	setStaffData,
	deleteSub,
	modifySub,
} from "store/admin/admin-action";
import { Subscription } from "modules/types/subscription";

export class RetrieveStaffDataStore extends RetrieveStaffDataApi {
	/**
	 * Retrieve all subscription owned by user
	 * @returns null
	 */

	public retrieveStaffData = async () => {
		let result: any = await this.retrieveStaffDataApi();
		setStaffData(result);
	};

	public addUserSubsData = async (body: any) => {
		let result: { subs: Subscription[] } = await this.addSubscriptionApi(
			body
		);
		updateUserSubs(result, body.userId);
	};

	public deleteUserSubsData = async (body: any) => {
		await this.deleteSubscriptionApi(body.request);
		deleteSub(body.userId, body.request);
	};

	public modifyUserSubsData = async (id: any, body: any) => {
		await this.modifySubscriptionApi(body);
		modifySub(id, body.type, body.subscriptionId);
	};
}
