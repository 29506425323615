import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import AdminRoutesComponent from "../../../private/admin/admin.routes";

import "./styles/routes.style.less";
import Layout from "antd/lib/layout/layout";

const PrivateRoutes = (props: RoutesManagerProps) => {
	return (
		<Layout style={{ marginTop: "47px" }}>
			<Routes>
				<Route>
					<Route path="admin/*" element={<AdminRoutesComponent />} />

					<Route
						path="rgpd/*"
						element={
							<>
								<h2>Rgpd</h2>
							</>
						}
					/>
					<Route
						path="*"
						element={
							<>
								<Navigate replace to="admin" />
							</>
						}
					/>
				</Route>
			</Routes>
		</Layout>
	);
};

const mapState = (state: RootState) => {
	let userRole: string = "";
	if (state.user && state.user.data)
		userRole = state.user.data?.role as string;

	return {
		role: userRole,
		// user,
		// init: project.init && dictionary.init,
	};
};
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(PrivateRoutes);
