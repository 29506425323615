import { Line } from "@ant-design/charts";
import {
	Layout,
	Button,
	Space,
	Card,
	Table,
	Typography,
	Select,
	Modal,
	Col,
	Row,
	Spin,
} from "antd";
import ViewHeader from "common/components/view-header/view-header";
import { format } from "date-fns";
import { Project } from "modules/types/project";
import { Subscription } from "modules/types/subscription";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { RetrieveStaffDataApi } from "services/retrieve-data/retrieve-staff-data/api/retrieve-staffdata.api.service";
import {
	getPlans,
	getTitle,
} from "services/subscriptions-references/subscriptions-references.service";
import { RootState } from "store/store";
import { UserInfosService } from "./services/settings-view-user-infos.service";
import ProjectsRequests from "common/components/projects-requests/projects-requests.component";

const { Option } = Select;

const SettingsViewUserInfos = (props: SettingsViewListUsersProps) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [subAdded, setSubAdded] = useState("");
	const [isRetrieve, setIsRetrieve] = useState<boolean>(false);
	const [userProjects, setUserProjects] = useState<any>([]);

	const { staffDataUsers } = props;
	const { userId } = useParams();
	const retrieveDataService: RetrieveDataService = new RetrieveDataService();
	const userInfosService: UserInfosService = new UserInfosService({
		userId: userId,
	});
	let userData = staffDataUsers[userId as string];

	useEffect(() => {
		(async () => {
			try {
				if (userId && userId in props.user_projects) {
					setUserProjects(props.user_projects[userId]);
				}
			} catch (error) {
				console.error(error);
			}
		})();
	}, [userId, props.user_projects]);

	useEffect(() => {
		(async () => {
			try {
				if (userId) {
					let _result: { [key: string]: any } = {};
					_result[userId] = userData;
					if (
						!Object.keys(props.user_projects).includes(
							userId as string
						)
					) {
						await retrieveDataService.retrieveOverviewUserData({
							users: _result,
						});
					}
				}
				setIsRetrieve(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [userId]);

	if (!isRetrieve) return <Spin />;

	const showModal = () => {
		setIsModalVisible(true);
	};
	const handleOk = () => {
		retrieveDataService.addSubscription({
			userId: userId,
			type: subAdded,
		});
		setIsModalVisible(false);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const visibleButton = (array: any[]) => {
		if (array.length === 8) {
			return { display: "none" };
		} else {
			return { display: "unset" };
		}
	};
	const handleChange = (value: any) => {
		setSubAdded(value);
	};

	let userProjectIds = userData?.projectIds?.map((_item: any) => {
		return _item?.id;
	});
	// console.log("userData", { userProjectIds, userData });

	if (userData?.stats?.subs == null) {
		return <></>;
	} else {
		var resultArrayStats: Subscription[] = Object.values(
			userData.stats.subs
		);
		var userPlans = resultArrayStats.map((element: any) => {
			return element?.type;
		});
		let createdAt;
		try {
			createdAt = format(Date.parse(userData.createdAt), "yyyy-MM-dd");
		} catch (error) {
			console.error(error);
		}
		let lastConnection;
		try {
			lastConnection = format(
				Date.parse(userData.lastConnection),
				"yyyy-MM-dd"
			);
		} catch (error) {
			console.error(error);
		}

		return (
			<Layout>
				<ViewHeader
					title={
						userData.profile.firstName +
						" " +
						userData.profile.lastName
					}
					subtitle={userData.email}
					// extra={
					// 	<Link
					// 		to={{
					// 			pathname: "info",
					// 		}}>
					// 		<Button type="primary">User overview</Button>
					// 	</Link>
					// }
				/>
				<div style={{ padding: "32px" }}>
					<Card
						title={
							<Typography.Title
								level={4}
								style={{
									margin: 0,
									wordBreak: "break-word",
								}}>
								Informations
							</Typography.Title>
						}>
						<Row key={"Row1"}>
							<Col span={8}>
								<div key={"Email"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										Email :
									</Typography.Title>{" "}
									<Typography.Text>
										{userData.email}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Creation"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										Created :
									</Typography.Title>{" "}
									<Typography.Text>
										{createdAt}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Last Connection"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										Last Connection :
									</Typography.Title>{" "}
									<Typography.Text>
										{lastConnection}
									</Typography.Text>
								</div>
							</Col>
						</Row>

						<Row key={"Row2"}>
							<Col span={8}>
								<div key={"Datasets"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(userData.stats.ndataSets)
											.length > 1
											? "Datasets"
											: "Dataset"}{" "}
										:
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(
												userData.stats.ndataSets
											).length
										}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Campaign"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(userData.stats.ncampaign)
											.length > 1
											? "Campaigns"
											: "Campaign"}{" "}
										:
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(
												userData.stats.ncampaign
											).length
										}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Dictionnary"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(
											userData.stats.ndictionary
										).length > 1
											? "Dictionnaries"
											: "Dictionnary"}{" "}
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(
												userData.stats.ndictionary
											).length
										}
									</Typography.Text>
								</div>
							</Col>
						</Row>

						<Row key={"Row3"}>
							<Col span={8}>
								<div key={"Groups"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(userData.stats.ngroup)
											.length > 1
											? " Groups :"
											: " Group :"}{" "}
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(userData.stats.ngroup)
												.length
										}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Annotation group"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(userData.stats.ngroup)
											.length > 1
											? "Annotation groups"
											: "Annotation group"}{" "}
										:
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(userData.stats.ngroup)
												.length
										}
									</Typography.Text>
								</div>
							</Col>
							<Col span={8}>
								<div key={"Projects"}>
									<Typography.Title
										level={5}
										style={{
											margin: 0,
											wordBreak: "break-word",
										}}>
										{Object.values(userData.stats.nproject)
											.length > 1
											? "Projects"
											: "Project"}{" "}
									</Typography.Title>
									<Typography.Text>
										{
											Object.values(
												userData.stats.nproject
											).length
										}
									</Typography.Text>
								</div>
							</Col>
						</Row>
					</Card>
					<ProjectsRequests projectIds={userProjectIds} />

					<Card
						title={
							<Typography.Title
								level={4}
								style={{
									margin: 0,
									wordBreak: "break-word",
								}}>
								Subs
							</Typography.Title>
						}>
						{/* <Typography.Title
								level={5}
								style={{
									margin: 0,
									wordBreak: "break-word",
								}}>
								Project usage
							</Typography.Title>
							<Line data={data_array} {...config} /> */}
						<div key={"Subs"} style={{ marginTop: "30px" }}>
							<Table
								dataSource={resultArrayStats!}
								columns={userInfosService.columns}
							/>

							<Button
								style={visibleButton(resultArrayStats)}
								onClick={showModal}>
								Ajouter un abonnement
							</Button>

							<Modal
								title="Sélectionner un abonnement"
								visible={isModalVisible}
								onOk={handleOk}
								onCancel={handleCancel}>
								<Select
									onChange={handleChange}
									defaultValue={""}
									style={{ width: 120 }}>
									{getPlans()
										.filter(
											(index) =>
												userPlans.indexOf(index) === -1
										)
										.map((element: any) => {
											return (
												<Option
													key={element + "2"}
													value={element}>
													{getTitle(element)}
												</Option>
											);
										})}
								</Select>
							</Modal>
						</div>
					</Card>
				</div>
			</Layout>
		);
	}
};

const mapState = (state: RootState) => {
	return {
		userData: state.user.data,
		staffDataUsers: state.admin.staffData.users,
		overview: state.overview.overall_data,
		user_projects: state.overview.user_projects_map,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SettingsViewListUsersProps = PropsFromRedux;

export default connector(SettingsViewUserInfos);
